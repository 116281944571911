function CountDownTimer(dt, id, days1, days2, day) {
    var end = new Date(dt);

    var _second = 1000;
    var _minute = _second * 60;
    var _hour = _minute * 60;
    var _day = _hour * 24;
    var timer, daysText;

    function showRemaining() {
        var now = new Date();
        var distance = end - now;
        if (distance < 0) {
            clearInterval(timer);
            document.getElementById(id).innerHTML = "EXPIRED!";

            return;
        }
        var days = Math.floor(distance / _day) + 1;

        if (days >= 5) {
            daysText = days1;
        } else if (days < 5 && days !== 1) {
            daysText = days2;
        } else if (days === 1) {
            daysText = day;
        }

        document.getElementById(id).innerHTML = days + " " + daysText;
    }

    timer = setInterval(showRemaining, 1000);
}

/** Definice Flash message */
function flashMessage(message, type) {
    let typ = type;
    if (type === "g") {
        typ = "success";
    } else if (type === "w") {
        typ = "warning";
    } else if (type === "r") {
        typ = "error";
    }

    toastr.options = {
        closeButton: false,
        debug: false,
        newestOnTop: true,
        progressBar: true,
        positionClass: "toast-top-center",
        preventDuplicates: true,
        onclick: null,
        showDuration: "300",
        hideDuration: "1000",
        timeOut: "5000",
        extendedTimeOut: "1000",
        showEasing: "swing",
        hideEasing: "linear",
        showMethod: "fadeIn",
        hideMethod: "fadeOut",
    };
    toastr[typ](message);
}

function toggleFormControls(checkBoxSelector, divSelector, inverse) {
    $(document).on("change", checkBoxSelector, function () {
        $(divSelector).slideToggle(500);
    });
    const checked = $(checkBoxSelector).prop("checked");
    if ((!inverse && !checked && $(checkBoxSelector).length) || (inverse && checked)) $(divSelector).hide();
}

/**
 * Snippet Modals + spinner
 */
NProgress.configure({
    showSpinner: false,
});

const realColumn = (table, i) => {
    if (table.colReorder) {
        const idx = table.colReorder.transpose(i);

        return {
            index: idx,
            column: table.column(idx),
        };
    }

    return {
        index: i,
        column: table.column(i),
    };
};

function dtDefaultInitComplete() {
    let table = this.api();
    let savedState = table.state();

    table.columns().every(function (i) {
        let column = this;
        let index = i;
        if (table.colReorder) {
            index = table.colReorder.transpose(i);
            column = table.column(index);
        }

        let footer = $(column.footer());
        if (footer.hasClass("search")) {
            let value = savedState ? savedState.columns[index].search.search : "";

            let inpt = $(
                `<input class="dt-inf-search" type="text" placeholder="" value="${value}" data-column="${i}">`
            );
            $(inpt).on("keyup change clear", function () {
                const colIndex = $(this).data("column");

                const col = realColumn(table, colIndex);
                col.column.search($.fn.DataTable.ext.type.search.string(this.value)).draw();
            });

            footer.html(inpt);
        } else if (footer.hasClass("filter-select")) {
            let value = savedState ? savedState.columns[index].search.search : "";

            const isMultiple = footer.data("multiple");
            const isSelectpicker = footer.data("selectpicker");

            let inpt = $(`<select class="${isSelectpicker ? "render-selectpicker" : "dt-inf-filter-select"}" ${
                isMultiple ? "multiple" : ""
            } data-column="${i}">
                ${isMultiple ? "" : '<option value=""></option>'}
            </select>`);

            $(inpt).on("change", function (e) {
                if ($(e).hasClass("placeholder")) {
                    $(this).addClass("placeholder");
                } else {
                    $(this).removeClass("placeholder");
                }

                const colIndex = $(this).data("column");

                const col = realColumn(table, colIndex);

                if (footer.hasClass("use-regex")) {
                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                    col.column.search(val ? "^" + val + "$" : "", true, false).draw();
                } else {
                    const val = $(this).val();

                    if (isMultiple) {
                        if (val && val.length > 0) {
                            const searchTerm = val.map((str) => `\\b${str}\\b`).join("|");

                            col.column.search(searchTerm, true, false).draw();
                        } else {
                            col.column.search("").draw();
                        }
                    } else {
                        col.column.search($.fn.DataTable.ext.type.search.string(val)).draw();
                    }
                }
            });

            let optionSet = [];
            let optionsCount = 0;

            if (footer.data("custom-options")) {
                const options = footer.data("custom-options");

                Object.entries(options).forEach((entry) => {
                    const [k, v] = entry;

                    let option = $(`<option value="${k}">${v}</option>`);
                    let cleanedSearch = $.fn.DataTable.ext.type.search.string(k);
                    let cleanedRegex = "\\b" + k + "\\b";
                    if (
                        cleanedSearch == value ||
                        cleanedRegex == value ||
                        (isMultiple && value.includes(cleanedRegex))
                    ) {
                        option.prop("selected", true).attr("selected", "selected");
                    }
                    inpt.append(option);
                    optionsCount += 1;
                });
            } else {
                column
                    .data()
                    .unique()
                    .sort()
                    .each(function (d, j) {
                        if (d && d.length > 0) {
                            let cleaner = document.createElement("div");
                            cleaner.innerHTML = d;
                            let cleaned = cleaner.textContent || cleaner.innerText || "";
                            let customSearch = $(cleaner).find("span.data-search");
                            if (customSearch) {
                                if (customSearch.text().length > 0) {
                                    cleaned = customSearch.text();
                                    if (!optionSet.includes(cleaned)) {
                                        optionSet.push(cleaned);
                                    } else {
                                        return true;
                                    }
                                }
                            }

                            let option = $(`<option value="${cleaned}">${cleaned}</option>`);
                            let cleanedSearch = $.fn.DataTable.ext.type.search.string(cleaned);
                            let cleanedRegex = "^" + cleaned + "$";
                            if (cleanedSearch == value || cleanedRegex == value) {
                                option.prop("selected", true).attr("selected", "selected");
                            }
                            inpt.append(option);
                            optionsCount += 1;
                        }
                    });
            }

            if (optionsCount === 0) {
                inpt.prop("disabled", true);
            }

            footer.html(inpt);
        }
    });

    const header = this.api().table().header();
    $("tr", this.api().table().footer()).appendTo(header);

    if ($(header).find("select.render-selectpicker").length > 0) {
        $(header).find("select.render-selectpicker").selectpicker({
            container: "body",
            noneSelectedText: "Nevybráno..",
            liveSearch: true,
            liveSearchNormalize: true,
            size: 5,
            width: "100%",
        });
    }
}

$(function () {
    "use strict";

    $(".preloader").fadeOut(200, () => {
        $("#main-wrapper").removeClass("preloader-no-anim");
    });

    $("select").on('select2:select', function (e) {
        $('.select2-search__field').val('').focus();
    });

    $("#main-wrapper").AdminSettings({
        Theme: false,
        Layout: "vertical",
        LogoBg: "skin6",
        NavbarBg: "skin1",
        SidebarType: "full",
        SidebarColor: "skin6",
        SidebarPosition: true,
        HeaderPosition: true,
        BoxedLayout: false,
    });

    Chart.defaults.font.family = "Roboto, sans-serif";

    $.nette.ext("nprogress", {
        start: function () {
            window.NProgress.start();
        },
        complete: function () {
            window.NProgress.done();
        },
    });

    $.nette.ext(
        "modals",
        {
            before: function () {
                this.wasModalOpenedBeforeAjaxRequest = this.isModalOpened();
                this.lastOpenedModalId = this.lastOpenedModal();

                if (this.wasModalOpenedBeforeAjaxRequest) {
                    $(".modal-ajax .modal-loader").show();
                    $(".modal-ajax a.btn, .modal-ajax button").attr("disabled", true).addClass("disabled");
                }
            },
            success: function (payload) {
                $(".modal-ajax .modal-loader").hide();
                $(".modal-ajax a.btn, .modal-ajax button").attr("disabled", false).removeClass("disabled");

                if (payload.modalFormErrors) {
                    payload.modalFormErrors.forEach((e) => {
                        $(".modal-errors").append("<p>" + e + "</p>");
                    });
                } else {
                    $(".modal-errors").empty();
                }

                if (payload.keepModal) return;

                if (payload.isModal) {
                    if (!this.wasModalOpenedBeforeAjaxRequest) {
                        $(".modal-ajax").modal("show");
                    } else {
                        if (this.lastOpenedModalId === payload.modalId) {
                            $(".modal-ajax").removeClass("fade");
                        }
                        $(".modal-ajax").addClass("in").css({ display: "block" }).modal("show");
                        $(".modal-backdrop").removeClass("fade");
                        $(".modal-backdrop:not(:last)").remove();
                        window.setTimeout(function () {
                            $(".modal-ajax").addClass("fade");
                            $(".modal-backdrop").addClass("fade");
                        }, 200);
                    }
                } else {
                    $(".modal-backdrop").remove();
                    $("body").removeClass("modal-open");
                    $(".modal-ajax").modal("hide");
                }
            },
        },
        {
            wasModalOpenedBeforeAjaxRequest: false,
            isModalOpened: function () {
                return $("body").hasClass("modal-open");
            },
            lastOpenedModal: function () {
                return $(".modal-ajax.show").attr("id");
            },
        }
    );

    $(document).on("show.bs.dropdown", ".dropdown-move-me-outside", function (e) {
        var dropdown = $(e.target).find(".dropdown-menu");
        dropdown.appendTo("body");
        $(this).on("hidden.bs.dropdown", function () {
            dropdown.appendTo(e.target);
        });
    });

    $(document).on("change", ".dataTables_length select", function (e) {
        let id = $(this).attr("name");
        let storageName = "default_" + id;
        localStorage.setItem(storageName, $(this).val());
    });

    $(document).on("show.bs.modal hide.bs.modal", ".modal", function () {
        $("body").css("padding-right", "0");
    });

    $(".left-sidebar")
        .on("mouseenter", function () {
            $(".navbar-header").addClass("expand-logo");
        })
        .on("mouseleave", function () {
            $(".navbar-header").removeClass("expand-logo");
        });

    // this is for close icon when navigation open in mobile view
    $(".nav-toggler").on("click", function () {
        $("#main-wrapper").toggleClass("show-sidebar");
        $(".nav-toggler i").toggleClass("fa-bars");
        if ($(".nav-toggler i").hasClass("fa-bars")) {
            $(".nav-toggler i").removeClass("fa-times");
        } else {
            $(".nav-toggler i").addClass("fa-times");
        }
    });

    $(".search-box a, .app-search .srh-btn").on("click", function () {
        $(".app-search").toggle(200);
        $(".app-search input").trigger("focus");
        $(this).closest("form").trigger("submit");
    });

    $(".datepicker")
        .datepicker({
            autoclose: true,
            clearBtn: true,
            daysOfWeekHighlighted: [0, 6],
            enableOnReadonly: false,
            language: "cs",
            format: "dd.mm.yyyy",
            todayHighlight: true,
        })
        .on("changeDate", function (e) {
            let inptId = $(this).data("update-id");
            let inpt = $("#" + inptId);

            let newDate = moment(e.date, "dd.mm.YYYY").format("YYYY-MM-DD");
            inpt.val(newDate).trigger("change");
        });

    $(".datepicker").each(function () {
        let inptId = $(this).data("update-id");
        let inpt = $("#" + inptId);

        let d = inpt.val();
        if (d) {
            let newDate = moment(e.date, "YYYY-MM-DD").format("dd.mm.YYYY");
            $(this).val(newDate);
        }
    });

    $.widget.bridge("uitooltip", $.ui.tooltip);
    $('[data-toggle="tooltip"]').tooltip({ trigger: "hover" });
    $('[data-toggle="popover"]').popover();

    /*$('.message-center, .customizer-body, .scrollable').perfectScrollbar({
        wheelPropagation: !0
    });*/

    $(".message-center, .customizer-body, .scrollable").each(function () {
        let el = $(this)[0];
        const ps = new PerfectScrollbar(el);
    });

    //Auto-close notification dropdown-menu fix
    $(".mailbox").on("click wheel", function (e) {
        e.preventDefault();
        e.stopPropagation();
    });

    $("body, .page-wrapper").trigger("resize");

    $(".list-task li label").on("click", function () {
        $(this).toggleClass("task-done");
    });

    $('a[data-action="collapse"]').on("click", function (e) {
        e.preventDefault();
        $(this).closest(".card").find('[data-action="collapse"] i').toggleClass("ti-minus ti-plus");
        $(this).closest(".card").children(".card-body").collapse("toggle");
    });
    // Toggle fullscreen
    $('a[data-action="expand"]').on("click", function (e) {
        e.preventDefault();
        $(this).closest(".card").find('[data-action="expand"] i').toggleClass("mdi-arrow-expand mdi-arrow-compress");
        $(this).closest(".card").toggleClass("card-fullscreen");
    });
    // Close Card
    $('a[data-action="close"]').on("click", function () {
        $(this).closest(".card").removeClass().slideUp("fast");
    });

    $(document).on("click", ".mega-dropdown", function (e) {
        e.stopPropagation();
    });

    $(".rodne-cislo").on("change", function () {
        let rc = $(this).val();
        let len = rc.length;
        if (len >= 9) {
            let part = rc.substr(0, 6);
            let day = parseInt(part.substr(4, 2));
            let month = parseInt(part.substr(2, 2));
            if (month > 12) {
                month -= 50;
            }
            let year = parseInt(part.substr(0, 2));
            if (year < 54 && len > 9) {
                year += 2000;
            } else {
                year += 1900;
            }
            let parsed = year + "-" + ("0" + month).slice(-2) + "-" + ("0" + day).slice(-2);
            console.log(parsed);
            $(".datum-narozeni").val(parsed);
        }
    });

    $(".show-left-part").on("click", function () {
        $(".left-part").toggleClass("show-panel");
    });
});
